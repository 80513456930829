import { Component, TemplateRef, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { ModalService } from './modal.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ecommerce-modal',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
})
export class ModalComponent {
  customizeContent!: TemplateRef<unknown>;
  title!: string;
  description!: string;
  isOpen!: boolean;
  itemsCount!: number;
  hasCloseIcon!: boolean;
  formGroup!: FormGroup;

  constructor(private readonly modalService: ModalService) {
    this.updateModal();
  }

  closeModal() {
    this.isOpen = false;
    document.body.classList.remove('body-no-scroll');
    if (this.formGroup) {
      this.formGroup.reset();
    }
  }

  updateModal() {
    effect(() => {
      const modal = this.modalService.modalContent();
      this.title = modal.title as string;
      this.description = modal.description as string;
      this.customizeContent = modal.customizeContent as TemplateRef<unknown>;
      this.isOpen = modal.isOpen;
      this.hasCloseIcon = modal.hasCloseIcon;
      this.formGroup = modal.formGroup as FormGroup;

      if (this.isOpen) {
        document.body.classList.add('body-no-scroll');
      } else {
        document.body.classList.remove('body-no-scroll');
      }
    });
  }
}
